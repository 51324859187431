@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@tailwind base;
@tailwind components;

@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

@layer utilities {
  .rtl {
    direction: rtl;
  }
}

[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: lato;
}

.highlight-image > span {
  z-index: 3;
}

.masked {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, transparent 100%);
}

.hero-banner-big::after {
  position: absolute;
  content: " ";
  background-image: url(../public/assets/glitch.jpeg);
  background-repeat: repeat;
  opacity: 0.01;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
}

.about-image {
  position: relative;
  z-index: 10;
}

.about-image > div {
  position: inherit;
  z-index: 10;
}

.about-image::before {
  position: absolute;
  content: " ";
  background-image: url(../public/assets/bg-filler.svg);
  background-repeat: no-repeat;
  opacity: 0.5;
  height: 400px;
  z-index: 1;
  width: 500px;
  top: 0px;
  right: -140px;
  transform: rotate(360deg) scale(1.5);
}

.blockContent > p {
  @apply text-lg;
  @apply text-gray-400;
  @apply grow;
}

.blockContent > h1 {
  font-size: 3.25rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.02em;
  @apply mb-4;
}

.blockContent > h2 {
  @apply text-2xl;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.02em;
}

.blockContent > ul {
  @apply my-8;
}

.blockContent > ul > li {
  list-style: square;
  @apply ml-4;
}

.blockContent > a {
  text-decoration: underline;
  @apply ml-4;
}

.blockContent > h3,
h4,
h5,
h6 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.375;
  letter-spacing: -0.01em;
  @apply pb-6;
  @apply pt-6;
}

mux-video > video {
  object-fit: cover;
}
